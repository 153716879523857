<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col xl="4" md="6">
        <ecommerce-medal :data="data.congratulations" :total="Total"/>
      </b-col>
      <b-col xl="8" md="6">
        <ecommerce-statistics :data="data.statisticsItems" :total="Total"/>
      </b-col>
    </b-row>

        <b-row class="match-height">
          <b-col lg="2" md="2" cols="2">
            <ecommerce-profit-chart :data="data.statisticsOrder" :total="Total"/>

            <ecommerce-profit-chart :data="data.statisticsProfit" :total="Total"/>
          </b-col>

        <b-col lg="10">
        <ecommerce-company-table :table-data="companyTable" />
      </b-col>
        </b-row>

  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import EcommerceMedal from "./EcommerceMedal.vue";
import EcommerceStatistics from "./EcommerceStatistics.vue";
import EcommerceOrderChart from "./EcommerceOrderChart.vue";
import EcommerceProfitChart from "./EcommerceProfitChart.vue";
import EcommerceCompanyTable from "./EcommerceCompanyTable.vue";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import dashboardStoreModule from "../dashboardStoreModule";
import useDashboard from "./useDashboard";


export default {
  components: {
    BRow,
    BCol,

    EcommerceMedal,
    EcommerceStatistics,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceCompanyTable,
  },
  data() {
    return {
      data: {
        statisticsItems: [
          {
            icon: "TrendingUpIcon",
            color: "light-primary",
            title: "14",
            subtitle: "New Company",
            customClass: "mb-2 mb-xl-0",
          },
          {
            icon: "UserIcon",
            color: "light-info",
            title: "29",
            subtitle: "New Position",
            customClass: "mb-2 mb-xl-0",
          },
          {
            icon: "BoxIcon",
            color: "light-danger",
            title: "150",
            subtitle: "New Candidate",
            customClass: "mb-2 mb-sm-0",
          },
          {
            icon: "DollarSignIcon",
            color: "light-success",
            title: "10",
            subtitle: "Assigned",
            customClass: "",
          },
        ],
        statisticsProfit: {
           title: "26",
          subtitle: "Total Company",
          series: [
            {
              data: [0, 20, 5, 30, 15, 45],
            },
          ],
        },
        statisticsOrder: {
          title: "6248",
          subtitle: "Total Candidates",
          series: [
            {
              data: [45, 85, 65, 45, 65],
            },
          ],
        },
        companyTable: [
          {
            avatarImg: require("@/assets/images/icons/toolbox.svg"),
            title: "Dixons",
            subtitle: "meguc@ruj.io",
            avatarIcon: "MonitorIcon",
            avatarColor: "light-primary",
            avatarTitle: "Technology",
            viewTitle: "23.4k",
            viewsub: "in 24 hours",
            revenue: "891.2",
            sales: "68",
            loss: true,
          },
          {
            avatarImg: require("@/assets/images/icons/parachute.svg"),
            title: "Motels",
            subtitle: "vecav@hodzi.co.uk",
            avatarIcon: "CoffeeIcon",
            avatarColor: "light-success",
            avatarTitle: "Grocery",
            viewTitle: "78k",
            viewsub: "in 2 days",
            revenue: "668.51",
            sales: "97",
            loss: false,
          },
          {
            avatarImg: require("@/assets/images/icons/brush.svg"),
            title: "Zipcar",
            subtitle: "davcilse@is.gov",
            avatarIcon: "WatchIcon",
            avatarColor: "light-warning",
            avatarTitle: "Fashion",
            viewTitle: "162",
            viewsub: "in 5 days",
            revenue: "522.29",
            sales: "62",
            loss: false,
          },
          {
            avatarImg: require("@/assets/images/icons/star.svg"),
            title: "Owning",
            subtitle: "us@cuhil.gov",
            avatarIcon: "MonitorIcon",
            avatarColor: "light-primary",
            avatarTitle: "Technology",
            viewTitle: "214",
            viewsub: "in 24 hours",
            revenue: "291.01",
            sales: "88",
            loss: false,
          },
          {
            avatarImg: require("@/assets/images/icons/book.svg"),
            title: "Cafés",
            subtitle: "pudais@jife.com",
            avatarIcon: "CoffeeIcon",
            avatarColor: "light-success",
            avatarTitle: "Grocery",
            viewTitle: "208",
            viewsub: "in 1 week",
            revenue: "783.93",
            sales: "16",
            loss: true,
          },
          {
            avatarImg: require("@/assets/images/icons/rocket.svg"),
            title: "Kmart",
            subtitle: "bipri@cawiw.com",
            avatarIcon: "WatchIcon",
            avatarColor: "light-warning",
            avatarTitle: "Fashion",
            viewTitle: "990",
            viewsub: "in 1 month",
            revenue: "780.05",
            sales: "78",
            loss: false,
          },
          {
            avatarImg: require("@/assets/images/icons/speaker.svg"),
            title: "Payers",
            subtitle: "luk@izug.io",
            avatarIcon: "WatchIcon",
            avatarColor: "light-warning",
            avatarTitle: "Fashion",
            viewTitle: "12.9k",
            viewsub: "in 12 hours",
            revenue: "531.49",
            sales: "42",
            loss: false,
          },
        ],
      },
    };
  },
  created() {



  },
    setup() {
    const USER_APP_STORE_MODULE_NAME = "dashboard";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, dashboardStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

 const {
      companyTable,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      Total,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useDashboard();

     return {
      // Sidebar
     

      companyTable,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      Total,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleFilter,
      planFilter,
      statusFilter,
    };
    }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
