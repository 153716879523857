import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {

        fetchCompany(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${process.env.VUE_APP_BASE_URL}/api/Dashboard`, queryParams)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        fetchTotal(ctx) {
            return new Promise((resolve, reject) => {
                axios
                   .get(`${process.env.VUE_APP_BASE_URL}/api/Dashboard`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
  
  

    },
}