import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { isUserLoggedIn } from '@/auth/utils'
import router from '@/router'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()
  const isLoggedIn = isUserLoggedIn()
  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [

  ]
  const perPage = ref(7)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const companyTable = ref([])
  const Total= ref({
    openPosition:0,
    totalCompany:0,
    totalCandidates:0,
    newCompany:0,
    newPosition:0,
    newCandidate:0,
    assigned:0

  })

  const dataMeta = computed(() => {
    const localItemsCount =  0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {

    refetchData()
  })

  if (isLoggedIn)
  {
    store
      .dispatch('dashboard/fetchCompany', {
        pageSize: perPage.value,
        currentPage: currentPage.value,
        sortExpression: sortBy.value,
        sortDirection: isSortDirDesc.value?"desc":"asc",
       //searchEmail:searchQuery.value,
       SearchName:searchQuery.value,
      })
      .then(response => {
       
        const { items, totalCount } = response.data.data

        companyTable.value = items.sort((a,b) => (a.percent> b.percent)? -1 : ((b.percent> a.percent)? 1 : 0))
        totalUsers.value = totalCount
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching  list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }) 
  
      store
      .dispatch('dashboard/fetchTotal')
      .then(response => {
       
        const { data } = response.data

        Total.value = data
       
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching  list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }

    if (!isLoggedIn)  router.push("/login")

  const resolveUserRoleVariant = role => {
   
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    return 'primary'
  }

  return {
    companyTable,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    Total,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
